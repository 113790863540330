/*
    Validation Style
*/

 /* valid color */
 input.valid:not([type]), input.valid:not([type]):focus, input[type=text].valid:not(.browser-default), input[type=text].valid:not(.browser-default):focus,
  input[type=password].valid:not(.browser-default), input[type=password].valid:not(.browser-default):focus, input[type=email].valid:not(.browser-default), 
  input[type=email].valid:not(.browser-default):focus, input[type=url].valid:not(.browser-default), input[type=url].valid:not(.browser-default):focus, 
  input[type=time].valid:not(.browser-default), input[type=time].valid:not(.browser-default):focus, input[type=date].valid:not(.browser-default), 
  input[type=date].valid:not(.browser-default):focus, input[type=datetime].valid:not(.browser-default), input[type=datetime].valid:not(.browser-default):focus, 
  input[type=datetime-local].valid:not(.browser-default), input[type=datetime-local].valid:not(.browser-default):focus, input[type=tel].valid:not(.browser-default), 
  input[type=tel].valid:not(.browser-default):focus, input[type=number].valid:not(.browser-default), input[type=number].valid:not(.browser-default):focus, 
  input[type=search].valid:not(.browser-default), input[type=search].valid:not(.browser-default):focus, textarea.materialize-textarea.valid, 
  textarea.materialize-textarea.valid:focus, .select-wrapper.valid>input.select-dropdown {
    border-bottom: 1px solid #4CAF50;
    -webkit-box-shadow: 0 1px 0 0 #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50;
}  

/* invalid color  */
  input.invalid:not([type]), input.invalid:not([type]):focus, input[type=text].invalid:not(.browser-default), input[type=text].invalid:not(.browser-default):focus,
  input[type=password].invalid:not(.browser-default), input[type=password].invalid:not(.browser-default):focus, input[type=email].invalid:not(.browser-default), 
  input[type=email].invalid:not(.browser-default):focus, input[type=url].invalid:not(.browser-default), input[type=url].invalid:not(.browser-default):focus, 
  input[type=time].invalid:not(.browser-default), input[type=time].invalid:not(.browser-default):focus, input[type=date].invalid:not(.browser-default), 
  input[type=date].invalid:not(.browser-default):focus, input[type=datetime].invalid:not(.browser-default), input[type=datetime].invalid:not(.browser-default):focus, 
  input[type=datetime-local].invalid:not(.browser-default), input[type=datetime-local].invalid:not(.browser-default):focus, input[type=tel].invalid:not(.browser-default), 
  input[type=tel].invalid:not(.browser-default):focus, input[type=number].invalid:not(.browser-default), input[type=number].invalid:not(.browser-default):focus, 
  input[type=search].invalid:not(.browser-default), input[type=search].invalid:not(.browser-default):focus, textarea.materialize-textarea.invalid, 
  textarea.materialize-textarea.invalid:focus, .select-wrapper.invalid>input.select-dropdown, .select-wrapper.invalid>input.select-dropdown:focus {
    border-bottom: 1px solid #F44336;
    -webkit-box-shadow: 0 1px 0 0 #F44336;
    box-shadow: 0 1px 0 0 #F44336;
  }

  .no-margin-bottom{
      margin-bottom:0px;
  }



body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: 1.19rem;
    color: #000;
}

.social{

    ul{
        margin-bottom: .5rem;
        margin-top: .5rem;

        li{
            text-align: center;
            padding-right: 2px;
            padding-left: 2px;

            a {
                background: rgba(255,255,255,.1);
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                -moz-transition-duration: .6s;
                -webkit-transition-duration: .6s;
                -o-transition-duration: .6s;
                transition-duration: .6s;
                -webkit-box-shadow: 0 2px 10px rgba(52,61,71,.2);
                -moz-box-shadow: 0 2px 10px rgba(52,61,71,.2);
                box-shadow: 0 2px 10px rgba(52,61,71,.2);

                &:hover{
                    background: #ff820e;
                }

                i{
                    font-size: 24px;
                    line-height: 39px;
                    color: #fff;
                }
            }
        }
    }
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;

    li{
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
    }
}

.logo{
    width: 260px;
}

.header-top{
    background-color: #1976d2;

    .row{
        margin-bottom: 0;
    }

    .contact{
        margin-top:.6rem;

        li{
        color: #fff;
        font-size: 16px;
        margin-right: 1rem;
        opacity: .7;

            i{
                margin-right: .4rem;
                margin-top:-4px;
            }
        }
    }
}

.header-bottom {
    background: rgba(255,255,255,.92);
    -webkit-box-shadow: 0 4px 10px rgba(0,0,0,.1);
    -moz-box-shadow: 0 4px 10px rgba(0,0,0,.1);
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
    height: 85px;
    //margin-bottom: 10px;
    position: -webkit-sticky; // required for Safari
    position: sticky;
    top: 0; // required as well.
    .row {
        margin-bottom: 0;
    }

    nav {
        background-color: rgba(255, 255, 255, 0.92);
        box-shadow: none;
        margin-top: 12px;

        .sidenav-trigger {
            color: #1976D2;
        }

        .nav-item {
            color: #333333;
            font-size: 18px;
            font-weight: 700;
            padding: 0 10px;
            text-transform: uppercase;

            &:hover {
                color: #23527c;
                background: none;
            }
        }

        .button-collapse {
            color: #01579B;
        }
    }
}

footer {
    background-color: #212739;

    .footer-inner {
        background: url(../wwwroot/images/footer-bg.png) bottom center no-repeat;
        -webkit-background-size: contain;
        -o-background-size: contain;
        -moz-background-size: contain;
        background-size: contain;
    }

    .footer-middle {
        padding: 2rem 0;

        .copyright {
            background: #1976d2;
            border-radius: 4px;
            color: #888;
            padding: .4rem 2.4rem;
            overflow: hidden;

            .text {
                margin-top: 1rem;
                width: 50%;
                float: left;
                font-weight: 400;
                color: #fff;
            }
        }

        .footer-column {
            margin-bottom: 3.9rem;

            .logo {
                width: 215px;
            }

            h2 {
                font-size: 1.3rem;
                line-height: 2.25rem;
                text-transform: uppercase;
                margin-bottom: 2.4rem;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
            }

            .brand-logo {
                margin-top: .7rem;
                display: inline-block;
                color: #fff;
            }

            address {
                color: #888;
                margin-bottom: 1.1rem;
                font-size: 1rem;
            }

            .about-text {
                color: #888;
                margin-bottom: 1.1rem;
                font-size: 1.3rem;
            }

            li,
            a {
                font-size: 1.1rem;
                color: #888;
                line-height: 3rem;

                &:hover {
                    color: #ffff
                }
            }

            h5 {
                font-size: 1.1rem;
                color: #888;
            }

            #email {
                color: #888;
                background-color:none!important;
            }

            .contact-info {
                li, a {
                    font-size: 1rem;
                }
            }
        }
    }
}


@media (max-width: 767px){
    
    .social {
        text-align: center;
    }

    .header-top {
        .contact {
            text-align: center;
        }
    }

    .logo{
        width: 215px;
    }

    footer .footer-middle .copyright .text{
        margin-bottom: .5rem;
        width: 100%;
        float: none;
        text-align: center;
        margin-top: 1rem;
    }

    footer .footer-middle {
        padding: 3.3rem 0;
    }

    footer .footer-column{
        text-align: center;
    }
    
}


/*
    Home
*/


.videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section-inner{
    padding: 2rem 0;
}

.section-heading{
    margin-bottom: 4.2rem;
    text-align: center;
    font-size: 2.7rem;
    color: #212739;
    font-weight: 900;
    text-transform: uppercase;
    // margin: -6px 0 0 0;
}

.home-banner {
    background: url(../wwwroot/images/home-banner.jpg) top center no-repeat;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;

    .banner-inner {
        padding: 16.75rem 0 20.5rem;

        h3 {
            font-size: 3.9rem;
            line-height: 5.85rem;
            font-weight: 300;
            margin: 0;
            color: #3f51b5;
        }

        h2 {
            font-weight: 900;
            margin: 0 0 1.6rem;
            text-transform: uppercase;
            color: #212739;
            }


            p {
                color: rgba(50,51,51,.7);
                margin-bottom: 1.5rem;
                font-size: 1.5rem;
                line-height: 2.6rem;
            }
        }
    }

    .home-about {
        background: #fff;

        .row {
            margin-bottom: 0;
        }

        .about-content {

            /*h4 {
                font-size: 1.4rem;
                line-height: 2.25rem;
                font-weight: 400;
                color: #212739;
                text-align: center;
            }

            p {
                // font-size: 1.1rem;
                padding: 0;
                margin: 0;
                color: rgba(50,51,51,.7);
                line-height: 2.4rem;
            }*/
        }
    }

    .home-services {
        background: #1976d2;
        padding-top: 2rem;

        h2 {
            color: #fff;
            padding-bottom: 2rem;
        }

        h4 {
            margin: 0 0 0 0;
        }

        a {
            font-size: 1.7rem;
            color: #fff;
        }

        p {
            color: #fff;
            opacity: .7;
            margin-bottom: 1.5rem;
        }

        .read-more {
            opacity: .4;
            border-bottom: 1px solid transparent;
            font-size: 1rem;

            &:hover {
                opacity: 1;
                border-bottom: 1px solid #fff;
            }
        }
    }

    .home-workshops {
        background: #f9f9f9;

        h2 {
            padding-bottom: 2rem;
        }

        .workshop {
            padding: .25rem;
            background: #fff;
            text-align: center;
            margin-bottom: 1.3rem;

            figure {
                margin: 0;
            }

            .course-info {

                h3 {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    font-weight: 700;
                    color: #212739;
                    min-height: 60px;
                }
            }

            .enrol {
                margin-bottom: 20px;
            }
        }
    }
    /*
    Old style
*/
    main {
        flex: 1 0 auto;
    }

    .gray-text {
        color: #666;
    }


    .gray-background {
        background-color: #F7F7F7;
    }

    .material-icons, .icon-text {
        vertical-align: middle;
    }





    .page-heading {
        border-bottom: 2px solid #ff6f00;
        padding-bottom: 18px;
    }

    .validation-message-div {
        color: #F44336;
    }
    /* text input aurelia-validation messages */
    .md-input-validation {
        left: 0;
        /*color: #f44336;*/
        color: #f44336;
        font-size: 0.8rem;
        transition: opacity .2s ease-out;
        margin-top: -4px;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 0) {
        .md-input-validation-first {
            margin-top: -14px;
            margin-bottom: -2px;
        }
    }

    @media only screen and (min-width: 992px) {
        .md-input-validation-first {
            margin-top: -18px;
            padding-bottom: 3px;
        }
    }

    @media only screen and (min-width: 1200px) {
        .md-input-validation-first {
            margin-top: -19px;
        }
    }
    /*
      HomePage
*/
    .home-header {
        min-height: 347px;
        background: url(../wwwroot/images/bg-box-989a6da356434ef470f9f9ba04bba358.png);
        background-size: 960px auto;
        color: #ffffff;

        p {
            font-size: 20px;
        }
    }


    .home-paths {
        .learn-box {
            background-image: radial-gradient(circle at 1px 1px, #d7d7d7, #d7d7d7 1px, transparent 1px);
            background-repeat: repeat-x;
            background-size: 6px 2px;
            background-position: 0 0;
        }

        @media screen and (min-width: 480px) {
            .learn-box {
                background-image: radial-gradient(circle at 1px 1px, #d7d7d7, #d7d7d7 1px, transparent 1px);
                background-repeat: repeat-y;
                background-size: 2px 5px;
                background-position: 0 0;
            }
        }

        .learn-box:first-of-type {
            background: none;
        }

        p {
            min-height: 44px;
        }
    }

    .workshop-list-item {
        border: 1px solid #CCC;
        border-radius: 5px;
        padding: 16px;
        overflow: hidden;
        margin-bottom: 15px;

        .ribbon {
            background: #ff6f00;
            color: #FFF;
            margin: -16px -16px 0;
            padding: 0 6px;
            text-transform: uppercase;
            text-align: center;
            -webkit-transform: translateX(-50%) translate(31px,20px) rotate(-45deg);
            transform: translateX(-50%) translate(31px,20px) rotate(-45deg);
            font-size: 12px;
            line-height: 1.4;
            border-radius: 5px 5px 0 0;
        }

        .title {
            color: #333;
            min-height: 36px;
            font-weight: 700;
        }

        p {
            border-bottom: 1px solid #F97500;
        }
    }
    /* 
    Course page
*/
    .course-hero {
        height: 400px;
        background-size: cover;
        color: #ffffff;
    }

    .course-second-nav {
        border-bottom: 1px solid #CCC;
        background: #fff;
        /*.row{
        margin-bottom:0;
    }*/
        .course-tabs {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-content: space-between;

            a {
                display: flex;
                flex: 1;
                justify-content: center;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                color: rgba(1,87,155,.7);
                font-size: 14px;
                text-transform: uppercase;

                &:hover {
                    color: #01579b;
                }

                &.active {
                    color: #01579b;
                    border-bottom: solid 2px #0182E7;
                    background-color: rgba(1,87,155,.1);
                }
            }

            a.disabled:hover {
                color: rgba(1,87,155,.7);
                cursor: default;
            }
        }
    }

    .course-description {
        background: linear-gradient(#F0F0F0 0,#FFF 10%);
        /*color: #666;*/
    }

    .course-tile-image {
        width: 100%;
        height: 173px;
    }

    .digitaldesign-hero {
        background-image: url(../wwwroot/images/hero-course-3dmodeling-1600x355.jpg);
    }

    .game-design-hero {
        background-image: url(../wwwroot/images/hero-course-gaming-1600x355.jpg);
    }

    .robotic-hero {
        background-image: url(../wwwroot/images/hero-course-robotics-1600x355.jpg);
    }

    // .webdesign-hero {
    //     background-image: url(../wwwroot/images/WebDesign/header-image.jpg);
    // }

    .programming-hero {
        background-image: url(../wwwroot/images/programming/header-image.jpg);
    }
    /*
    Course page 
*/
    .course-page-hero {
        width: 100%;
        border-bottom: 1px solid #CCC;
    }

    .course-page-title {
        background: linear-gradient(#F0F0F0 0,#FFF 10%);
        width: 100%;
        color: #666;
    }

    .course-page-intro {
        border: solid #CCC;
        border-width: 1px 0;
        padding: 10px;
        margin-bottom: 30px;

        .row {
            margin-bottom: 0px;
        }

        .devider {
            border-left: 1px solid #CCC;
        }
    }




.course-details {
    .details-header {
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            font-size: 18px;
            padding-bottom:15px;
        }

        li:last-of-type{
            padding-bottom:0;
        }

        strong {
            font-weight: 700;
        }
    }

    .description {
        font-size: 17px;
        padding: 0;
        margin: 0;

        img {
            width: 100%;
            height: 500px;
        }

        .content {
            padding-left: 50px;
            padding-top: 80px;
            padding-right: 200px;
        }
    }

    .details {
        font-size: 17px;
        
        li{
            list-style:disc;
            margin-left:50px;
            
        }
    }
}


/*
    content Page
*/

.content-page .header {
    text-align: center;
    position: relative;
    z-index: 11;
    padding: 100px 0;
}

.content-page .header:before {
    background: #000 none repeat scroll 0 0;
    content: "\"";
    height: 100%;
    left: 0;
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=45)";
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.content-page .header h2 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.content-page .header .breadcromb {
    margin-top: 10px;
}

.content-page .header .breadcromb li {
    color: #ffffff;
    display: inline-block;
    font-family: "Raleway",sans-serif;
    font-weight: 600;
    margin: 0 5px;
    text-transform: capitalize;
    font-size: 15px;
}

.content-page .header .breadcromb li a {
    font-weight: 600;
    color: #fbb908;
}




p {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: 1.19rem;
}

.Heading-1 {
    font-size: 30px;
    color: #215698;
    font-variant: small-caps;
    text-transform: none;
    font-weight: 200;
}

.Heading-2 {
    font-size: 23px;
    color: #778289;
    font-variant: small-caps;
    text-transform: none;
    font-weight: 200;
}


.side-navigation-header {
    background: #215698 none repeat scroll 0 0;
    /*margin-bottom: 10px;*/
    padding: 10px 20px;
    text-align: left;
    text-transform: capitalize;
}

.side-navigation-header a {
    color: #fff;
    font-size: 18px;
}

.side-navigation-item {
    background: #f9f9f9 none repeat scroll 0 0;
    border-bottom: 1px solid #ddd;
}

.side-navigation-item a {
    color: #000;
    display: block;
    font-weight: 500;
    padding: 10px 15px;
    text-transform: capitalize;
    font-size: 16px;
}


@media screen and (max-width: 992px) {
    .course-second-nav .course-tabs {
        flex-direction: column;
    }
}